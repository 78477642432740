//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompHero from "@/components/03_comps/CompHero";
import CompCards from "@/components/03_comps/CompCards";
import BaseSidebar from "@/components/base/BaseSidebar";
import ItemShortlink from "@/components/05_items/ItemShortlink";

const axios = require("axios");
import scrollTrigger from "@/helper/scrollHandler.js";

export default {
  name: "LayoutDefault",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  transition: {
    name: "redirect",
    mode: "out-in"
  },
  components: {
    CompHero,
    BaseSidebar,
    CompCards,
    ItemShortlink
  },
  data() {
    return {
      links: []
    };
  },
  mounted() {
    scrollTrigger.load();
    this.$store.commit("getShortLinks", this.blok.shortlinks);
    this.checkUrl();

    document.querySelector(".nav").classList.add("link-site");
    document.querySelector("body").classList.add("link-site");
  },
  methods: {
    checkUrl() {
      const directUrl = window.location.hash;
      this.$store.state.shortLinks.forEach(link => {
        if (directUrl === link.name) {
          window.location = link.url.url;
        }
      });
    }
  },
  watch: {
    $route() {
      this.checkUrl();
    }
  }
};
