//
//
//
//
//
//
//
//

export default {
  name: "CompHero",
  props: ["headline"],
  components: {
    // CompContacts
  },
  methods: {}
};
