//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ItemCard",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      link: ""
    };
  },
  computed: {
    itemCard() {
      return this.blok;
    },
    getUrl() {
      const that = this;
      this.$store.state.shortLinks.forEach(link => {
        if (this.itemCard.linkname === link.name) {
          return (that.link = link.url.url);
        }
      });
    }
  }
};
