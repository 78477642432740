//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageVectary from "@/components/02_globals/PageVectary.vue";
import PageVectaryNavigation from "@/components/02_globals/PageVectaryNavigation.vue";

export default {
  name: "LayoutVectary",
  components: {
    PageVectary,
    PageVectaryNavigation
  },
  data() {
    return {
      enteredPass: "",
      passError: false,
      vctrApi: "",
      bgColorFromChild: "transparent"
    };
  },
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  computed: {
    splitID() {
      let input = this.blok.vectaryID;
      let output = "";

      if (input.includes("<iframe")) {
        output = input.split('"')[1];
      } else {
        output = input;
      }
      return output;
    },
    splitARID() {
      let input = this.blok.vectaryarid;
      console.log("AR" + input);
      let output = "";

      if (input.includes("<iframe")) {
        output = input.split('"')[1];
      } else {
        output = input;
      }
      return output;
    }
  },
  methods: {
    checkPass() {
      let val = document.getElementById("pass").value;
      this.enteredPass = val;

      if (this.enteredPass != this.password) {
        this.passError = true;
      }
    },
    onClickChild(value) {
      this.bgColorFromChild = value;
    }
  },
  mounted() {
    //document.body.style.background = this.blok.backgroundColor.color;
    document.querySelector(".nav").style.background = "transparent";
    document.querySelector(".nav").style.borderBottom = "0px";

    //this.createAndLoadVectaryScene();
  }
};
