//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BaseVectary",
  data() {
    return {
      loadingInterval: "",
      vectaryLoaded: false
    };
  },
  props: {
    vectaryID: {
      type: String,
      default: ""
    },
    bgcolor: {
      type: String,
      default: "white"
    },
    configurator: {
      type: String
    }
  },
  methods: {
    loadingVectary() {
      const that = this;
      this.loadingInterval = setInterval(function() {
        if (that.$store.state.vectaryInstanceReady) {
          that.vectaryLoaded = true;
          clearInterval(that.loadingInterval);
        }
      }, 500);
    }
  },
  computed: {
    configOpen() {
      return this.$store.state.vectaryConfigOpen;
    },
    vData() {
      return this.$store.state.vectaryData;
    },
    vInstanceReady() {
      return this.$store.state.vectaryInstanceReady;
    }
  },
  mounted() {
    this.$store.dispatch("initVectary", this.vectaryID);
    this.loadingVectary();
  }
};
