//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ItemSocial from "@/components/05_items/ItemSocial";
export default {
  name: "CompHero",
  props: ["socials", "contacts"],
  components: {
    ItemSocial
  }
};
