//
//
//
//
//
//
//
//
//
//
//
//

import ItemCard from "@/components/05_items/ItemCard";

export default {
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  components: {
    ItemCard
  },
  data() {
    return {
      cards: []
    };
  },
  methods: {
    getCards(e) {
      return (this.cards = e);
    }
  },
  computed: {
    reversedCardOrder() {
      return [...this.cards].reverse();
    }
  }
};
