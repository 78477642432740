//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompContacts from "@/components/03_comps/CompContacts";
export default {
  components: {
    CompContacts
  }
};
