//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageModelviewer from "@/components/02_globals/PageModelviewer.vue";
export default {
  name: "LayoutModelviewer",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  components: {
    PageModelviewer
  },
  data() {
    return {
      enteredPass: ""
    };
  },
  methods: {
    checkPass() {
      let val = document.getElementById("pass").value;
      this.enteredPass = val;

      if (this.enteredPass != this.password) {
        this.passError = true;
      }
    }
  },
  mounted() {
    document.querySelector(".nav").style.border = "none";
  }
};
