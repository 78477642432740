//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { VctrApi } from "@/helper/vectaryApi.js";

export default {
  name: "BaseVectaryNavigation",
  props: {
    bgcolor: {
      type: String,
      default: "white"
    },
    vectaryARID: {
      type: String,
      default: ""
    },
    headline: {
      type: String,
      default: ""
    },
    items: {
      type: Array
    },
    configurations: {
      type: Array
    },
    colors: {
      type: Array
    },
    animationheadline: {
      type: String,
      default: ""
    },
    colorheadline: {
      type: String,
      default: ""
    }
  },
  computed: {
    configOpen() {
      return this.$store.state.vectaryConfigOpen;
    },
    vInstanceReady() {
      return this.$store.state.vectaryInstanceReady;
    },
    vData() {
      return this.$store.state.vectaryData;
    },
    vGroups() {
      return this.$store.state.vectaryGroups;
    }
  },
  methods: {
    configHandle() {
      this.$store.commit("setVectaryConfigOpen");
    },
    setMaterial(group, parent, payload) {
      const el = this.vData.filter(
        item => item.type === "mesh" && item.parentName === parent
      )[0].name;

      const mat = this.vData.filter(
        item => item.type === "mesh" && item.name === payload
      )[0].material;

      window.vctrApi.setMaterial(el, mat);
      this.$store.commit("setVectaryMaterial", {
        group: group.name,
        mat: payload
      });
    },
    setMesh(parent, child) {
      const group = this.vGroups.filter(item => item.name === parent)[0]
        .childrenNames;
      group.forEach(item => {
        if (item === child) {
          window.vctrApi.setVisibility(item, true, false);
          this.$store.commit("hideMaterial");
          this.$store.commit("setVectaryActiveElements", {
            parent: parent,
            child: child
          });
        } else {
          window.vctrApi.setVisibility(item, false);
        }
      });
    },
    animateRotation(object, fromArray, toArray) {
      function animation() {
        VctrApi.Utils.animate(800, "easeInOutCubic", async timeFraction => {
          var intensity = VctrApi.Utils.lerp(fromArray, toArray, timeFraction);
          vctrApi.setRotationAbsolute(object, intensity);
        });
      }
      animation();
    },
    animationToggle() {
      let object = event.target.dataset.object;
      let from = event.target.dataset.from;
      let to = event.target.dataset.to;

      let oldFrom = from;
      let oldTo = to;

      event.target.classList.toggle("btn--active");

      let fromArray = from.split(",").map(Number);
      let toArray = to.split(",").map(Number);

      this.animateRotation(object, fromArray, toArray);

      event.target.setAttribute("data-from", oldTo);
      event.target.setAttribute("data-to", oldFrom);
    },
    colorChange(
      primary,
      primary_color,
      secondary,
      secondary_color,
      third,
      third_color
    ) {
      const that = this;
      this.$emit("clicked", primary_color);
      for (let i = 0; i < this.$store.state.vectaryData.length; i++) {
        let material = String(this.$store.state.vectaryData[i].material);
        console.log(material);
        const primaryItem = material.indexOf(primary);
        const secondayItem = material.indexOf(secondary);
        const thirdItem = material.indexOf(third);
        if (primaryItem != -1) {
          vctrApi.updateMaterial(this.$store.state.vectaryData[i].material, {
            color: primary_color
          });
        }
        if (secondayItem != -1) {
          vctrApi.updateMaterial(this.$store.state.vectaryData[i].material, {
            color: secondary_color
          });
        }
        if (thirdItem != -1) {
          vctrApi.updateMaterial(this.$store.state.vectaryData[i].material, {
            color: third_color
          });
        }
      }
    },
    configurate(item) {
      // RESET TO DEFAULT
      // for (let n = 0; n < this.$store.state.vectaryMaterialStyles.length; n++) {
      //   for (let s = 0; s < this.$store.state.vectaryData.length; s++) {
      //     if (
      //       this.$store.state.vectaryData[s].material ==
      //       this.$store.state.vectaryMaterialStyles[n].name
      //     ) {
      //       const updatedMaterial = {
      //         color: this.$store.state.vectaryMaterialStyles[n].color
      //       };
      //       vctrApi.updateMaterial(
      //         this.$store.state.vectaryData[s].material,
      //         updatedMaterial
      //       );
      //     }
      //   }
      // }
      for (let i = 0; i < item.length; i++) {
        if (item[i].component == "ItemNewColor") {
          for (let s = 0; s < this.$store.state.vectaryData.length; s++) {
            let material = String(this.$store.state.vectaryData[s].material);
            let old = material.indexOf(item[i].materialname);
            if (old != -1) {
              for (
                let n = 0;
                n < this.$store.state.vectaryMaterialStyles.length;
                n++
              ) {
                if (
                  material == this.$store.state.vectaryMaterialStyles[n].name
                ) {
                  const updatedMaterial = {
                    color: item[i].newcolor
                  };
                  vctrApi.updateMaterial(material, updatedMaterial);
                }
              }
            }
          }
        }

        if (item[i].component == "ItemNewMaterial") {
          for (let k = 0; k < this.$store.state.vectaryData.length; k++) {
            let material = String(this.$store.state.vectaryData[k].material);
            let old = material.indexOf(item[i].MaterialName);
            if (old != -1) {
              vctrApi.setMaterial(
                this.$store.state.vectaryData[k].name,
                item[i].NewMaterialName
              );
            }
          }
        }
        if (item[i].component == "ItemBackgroundColor") {
          this.$emit("clicked", item[i].bgcolornew.color);
        }
      }
    },
    handleSelectedConfig(event) {
      document.querySelectorAll(".btn-config").forEach(item => {
        item.classList.remove("active-config");
      });
      event.target.classList.add("active-config");
    }
  },
  mounted() {}
};
