module.exports = {
  load() {
    window.addEventListener("scroll", scrollHandler, true);

    function scrollHandler() {
      let scrollY = window.scrollY;
      handleBackground(scrollY)
      navigationProgress(scrollY)
    }

    function handleBackground(scrollY) {
      const main = document.querySelector('body');
      if (scrollY > 200) {
        main.classList.add('white')
      }
      else {
        main.classList.remove('white')
      }
    }

    function navigationProgress(scrollY) {
      const windowHeight = window.innerHeight;
      const documentHeight = document.querySelector('.Site').offsetHeight;
      let progressProcent = 100 / (documentHeight - windowHeight);
      const progressBar = document.querySelector('.nav__progress');
      progressBar.style.width = (progressProcent * scrollY) + '%';

    }
  }
}
