import { render, staticRenderFns } from "./CompContacts.vue?vue&type=template&id=3b561fb0&scoped=true&"
import script from "./CompContacts.vue?vue&type=script&lang=js&"
export * from "./CompContacts.vue?vue&type=script&lang=js&"
import style0 from "./CompContacts.vue?vue&type=style&index=0&id=3b561fb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b561fb0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ItemSocial: require('/opt/build/repo/components/05_items/ItemSocial.vue').default})
