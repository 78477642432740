//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PageModelviewer",
  data() {
    return {
      isMounted: false,
      passError: false,
      configOpen: true,
      modelLoaded: false,
      configVariants: false,
      configAnimations: false,
      headlineVariants: String,
      headlineAnimations: String
    };
  },
  mounted() {
    this.isMounted = true;
    this.loadComponent();
    this.initModelViewer();
    this.generateQRCode();
  },
  props: {
    config: Object
  },
  computed: {
    loadComponent() {
      return () => import("@google/model-viewer");
    }
  },
  methods: {
    checkPass() {
      let val = document.getElementById("pass").value;
      this.enteredPass = val;

      if (this.enteredPass != this.password) {
        this.passError = true;
      }
    },
    initModelViewer() {
      this.interval = requestAnimationFrame(() => {
        const modelViewer = document.getElementById("config-viewer");

        if (modelViewer != null) {
          if (!this.config.hideConfig) {
            cancelAnimationFrame(this.interval);
            this.createConfig(this.config.body[0]);
          } else {
            this.modelLoaded = true;
          }
        }
      }, 100);
    },
    createConfig(elem) {
      this.modelLoaded = false;
      const modelViewer = document.getElementById("config-viewer");
      const animationWrap = document.getElementById("controls__animations");
      const variantWrap = document.getElementById("controls__variants");

      // Set headlines of config

      modelViewer.addEventListener("load", () => {
        variantWrap.innerHTML = "";
        animationWrap.innerHTML = "";
        this.configVariants = false;
        this.configAnimations = false;
        // Create Scene
        modelViewer.shadowIntensity = elem.shadow;
        modelViewer.style.backgroundColor = elem.bgcolor.color;

        // Create Config
        if (elem.generateAnimations) {
          const animations = modelViewer.availableAnimations;
          if (animations.length > 0) {
            this.configAnimations = true;
          }
          for (const anim of animations) {
            const that = this;
            const option = document.createElement("button");
            option.dataset.anim = anim;
            option.textContent = anim;
            option.classList.add("btn-config");
            option.classList.add("animations-config");
            option.addEventListener("click", function() {
              that.toggleAnimation();
              that.setActiveBtn();
            });
            animationWrap.appendChild(option);
          }
        }

        if (elem.generateVariants) {
          const variants = modelViewer.availableVariants;
          modelViewer.variantName = variants[0];
          if (variants.length > 0) {
            this.configVariants = true;
          }
          for (const variant of variants) {
            const that = this;
            const option = document.createElement("button");
            option.dataset.variant = variant;
            option.textContent = variant;
            option.classList.add("btn-config");
            option.classList.add("variants-config");
            option.addEventListener("click", function() {
              that.toggleVariant();
              that.setActiveBtn();
            });
            if (variant == variants[0]) {
              option.classList.add("active");
            }
            variantWrap.appendChild(option);
          }
        }

        // Custom config
        if (elem.variants.length > 0) {
          const variants = elem.variants;
          modelViewer.variantName = variants[0].VariantName;
          modelViewer.style.backgroundColor = variants[0].bgcolor.color;
          this.configVariants = true;
          for (const variant of variants) {
            const that = this;
            const option = document.createElement("button");
            option.dataset.variant = variant.VariantName;
            option.dataset.bgcolor = variant.bgcolor.color;
            option.textContent = variant.buttonName;
            option.classList.add("btn-config");
            option.classList.add("variants-config");
            option.addEventListener("click", function() {
              that.customVariant();
              that.setActiveBtn();
            });
            if (variant.VariantName == variants[0].VariantName) {
              option.classList.add("active");
            }
            variantWrap.appendChild(option);
          }
        }

        if (elem.animations.length > 0) {
          const animations = elem.animations;
          this.configAnimations = true;
          for (const animation of animations) {
            const that = this;
            const option = document.createElement("button");
            option.dataset.anim = animation.AnimationName;
            option.dataset.duration = animation.duration;
            option.textContent = animation.buttonName;
            option.classList.add("btn-config");
            option.classList.add("animations-config");
            option.addEventListener("click", function() {
              that.customAnimation();
              that.setActiveBtn();
            });
            animationWrap.appendChild(option);
          }
        }

        this.headlineVariants = elem.headlineVariants;
        this.headlineAnimations = elem.headlineAnimations;
        this.modelLoaded = true;
      });
    },
    changeConfig(elem) {
      const modelViewer = document.getElementById("config-viewer");
      if (modelViewer.src == elem.model3d.filename) {
        return;
      } else {
        modelViewer.src = elem.model3d.filename;
        this.createConfig(elem);
      }
    },
    toggleAnimation() {
      const modelViewer = document.getElementById("config-viewer");
      const animationType = event.target.dataset.anim;
      modelViewer.animationName = animationType;
      modelViewer.play();
    },
    toggleVariant() {
      const modelViewer = document.getElementById("config-viewer");
      const variantType = event.target.dataset.variant;
      modelViewer.variantName = variantType;
    },
    customVariant(variant) {
      const modelViewer = document.getElementById("config-viewer");
      const variantType = event.target.dataset.variant;
      modelViewer.variantName = variantType;
      modelViewer.style.backgroundColor = event.target.dataset.bgcolor;
    },
    customAnimation(animation) {
      const modelViewer = document.getElementById("config-viewer");
      const animationType = event.target.dataset.anim;
      const animationDuration = event.target.dataset.duration;
      modelViewer.animationName = animationType;
      modelViewer.play();

      // pause animation when its done
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        modelViewer.pause();
      }, animationDuration);
    },
    setActiveBtn() {
      const elem = event.target;
      if (elem.classList.contains("model-config")) {
        const model = document.querySelectorAll(".model-config");
        model.forEach(element => {
          element.classList.remove("active");
        });
        elem.classList.add("active");
      }
      if (elem.classList.contains("variants-config")) {
        const model = document.querySelectorAll(".variants-config");
        model.forEach(element => {
          element.classList.remove("active");
        });
        elem.classList.add("active");
      }
      if (elem.classList.contains("animations-config")) {
        const model = document.querySelectorAll(".animations-config");
        model.forEach(element => {
          element.classList.remove("active");
        });
        elem.classList.add("active");
      }
    },
    toggleConfig() {
      return (this.configOpen = !this.configOpen);
    },
    generateQRCode() {
      if (this.config.arConfig) {
        const url =
          "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data==" +
          window.location.href;
        document.getElementById("QRCode").src = url;
      }
    }
  }
};
