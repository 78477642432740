//
//
//
//

export default {
  name: "ItemSocial",
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  computed: {
    itemShortlink() {
      return this.blok;
    }
  }
};
